import React from "react"
import axios from "axios"
import User from "../models/user"

const initialState = {
  loggedIn: false,
  permissions: [],
  roles: [],
}

/**
 * reducer init function
 * We use it to check if the user is loggedin with localstorage
 */
const init = () => {
  const token = localStorage.getItem("token")
  if (token) {
    axios.defaults.headers["Authorization"] = "Bearer " + token
    return new User({ ...initialState, loggedIn: true, token })
  } else {
    return new User(initialState)
  }
}

/**
 * List of allowed actions to be applied to the user object
 */
const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.token)
      axios.defaults.headers["Authorization"] = "Bearer " + action.token
      return new User({ ...state, loggedIn: true, token: action.token })

    case "LOGOUT":
      localStorage.removeItem("token")
      return new User(initialState)

    case "UPDATE_USER":
      return new User({
        ...state,
        name: action.name,
        id: action.user_id,
        employee_id: action.employee_id,
        is_admin: action.is_admin,
        permissions: action.permissions,
        roles: action.roles,
      })

    case "CHANGE_GROUP":
      var selectedGroup = localStorage.getItem("selectedGroup") || 1
      return new User({
        ...state,
        selectedGroup: selectedGroup,
      })

    default:
      throw new Error()
  }
}

const UserContext = React.createContext()
UserContext.displayName = "UserContext"

const UserContextProvider = ({ children }) => {
  const userReducer = React.useReducer(reducer, initialState, init)

  return (
    <UserContext.Provider value={userReducer}>{children}</UserContext.Provider>
  )
}

const useUser = () => React.useContext(UserContext)

export { UserContext, UserContextProvider, useUser }

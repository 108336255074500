import React from "react"
import { useUser } from "../../contexts/user"
import { Stats } from "../../components/stats"
import {
  FormField,
  Select,
  Button,
  Heading,
  Tabs,
  Tab,
  Box,
  Grid,
  SelectMultiple,
} from "grommet"
import { useLayers } from "../../contexts/layers"
import { compileStatusStatistics as compileStatusStatisticsOffer } from "../../models/offer"
import useCollectionDataset from "../../hooks/useCollectionDataset"
import { MyCalendar, MyGantt } from "../../components"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"
import { useFullscreen, useToggle } from "react-use"
import { useSelector } from "react-redux"
import { useRescheduleLeaveMutation } from "../../redux/leaveSlice"
import {
  useGetTasksQuery,
  useRescheduleTaskMutation,
} from "../../redux/tasksSlice"
import {
  selectAllProjects,
  useRescheduleProjectMutation,
} from "../../redux/projectsSlice"
import { selectAllEvents, selectAllOffers } from "../../redux/selectors"
import { useGetHolidaysQuery } from "../../redux/holidaysSlice"
import { LeavesPanel } from "./panels/leavesPanel"
import { TimesheetsPanel } from "./panels/timesheetsPanel"
import { compileStatusStatistics as compileStatusStatisticsProject } from "../../models/project"
import { NextTasksPanel } from "./panels/NextTasksPanel"
import { TodayPanel } from "./panels/TodayPanel"
import { AdminVisibility } from "../../components/AdminVisibility"
import { ProjectResource } from "../../resources/ProjectResource"
import { LeaveResource } from "../../resources/LeaveResource"
import { SimpleOfferResource } from "../../resources/SimpleOfferResource"
import { RESSOURCE_LOAD_OPTIONS } from "../../utils/constants"

const colors = {
  tasks: "#14bd60",
  projects: "#02a2dd",
  leaves: "#ffca58",
  holidays: "purple",
}

const defaultEventsFilters = {
  type: [],
  year: "now",
  viewMode: "Day",
  dateRange: {
    start: DateTime.now().startOf("day"),
    end: DateTime.now().plus({ year: 1 }),
  },
}

const defaultFilters = {
  belongsTo: "all",
  status: [],
  reminderLevel: [],
  in: [],
  dateRange: { end: null, start: null },
}

function Dashboard() {
  const fullscreenRef = React.useRef(null)
  const [user] = useUser()
  const [show, toggle] = useToggle(false)
  const isFullscreen = useFullscreen(fullscreenRef, show, {
    onClose: () => toggle(false),
  })
  useGetHolidaysQuery()

  useGetTasksQuery(undefined, {
    ...RESSOURCE_LOAD_OPTIONS,
    skip: !user.can("task_list"),
  })

  ProjectResource.methods.load(undefined, {
    ...RESSOURCE_LOAD_OPTIONS,
    skip: !user.can("project_list"),
  })

  LeaveResource.methods.load(undefined, {
    ...RESSOURCE_LOAD_OPTIONS,
    skip: !user.can("leave_list"),
  })

  SimpleOfferResource.methods.load(undefined, {
    ...RESSOURCE_LOAD_OPTIONS,
    skip: !user.can("offer_list"),
  })

  const events = useSelector(state => selectAllEvents(state))
  const offers = useSelector(state => selectAllOffers(state))
  const projects = useSelector(state => selectAllProjects(state))
  const [tasks, setTasks] = React.useState({ tasks: [], date: Date.now() })

  const rescheduleMutations = {
    tasks: useRescheduleTaskMutation()[0],
    projects: useRescheduleProjectMutation()[0],
    leaves: useRescheduleLeaveMutation()[0],
  }

  const [, dispatch] = useLayers()
  const navigate = useNavigate()

  const [filters, setFilters] = React.useState({
    ...defaultFilters,
  })
  const [eventFilters, setEventFilters] = React.useState({
    ...defaultEventsFilters,
  })

  const filtered = useCollectionDataset({ data: offers, filters: filters })
  const filteredProjects = useCollectionDataset({
    data: projects,
    filters: filters,
  })

  const handleEventsSelectChange = React.useCallback(
    ({ value }) => {
      let start
      let end = DateTime.now()

      switch (value) {
        case "now":
          start = DateTime.now().startOf("day")
          end = DateTime.now().plus({ year: 1 })
          break

        case "last_year":
          start = DateTime.now().minus({ year: 1 }).startOf("year")
          end = DateTime.now().minus({ year: 1 }).endOf("year")
          break

        case "next_year":
          start = DateTime.now().plus({ year: 1 }).startOf("year")
          end = DateTime.now().plus({ year: 1 }).endOf("year")
          break

        case "this_year":
        default:
          start = DateTime.now().startOf("year")
          end = DateTime.now().endOf("year")
          break
      }

      setEventFilters(filters => ({
        ...filters,
        year: value,
        dateRange: { start, end },
      }))
    },
    [setEventFilters]
  )

  const handleSelectChange = React.useCallback(
    ({ value }) => {
      let start
      let end = DateTime.now()

      switch (value.value) {
        case "last 7 days":
          start = DateTime.now().minus({ days: 7 })
          break
        case "last 28 days":
          start = DateTime.now().minus({ days: 28 })
          break
        case "last 3 months":
          start = DateTime.now().minus({ months: 3 })
          break
        case "this_year":
          start = DateTime.now().startOf("year")
          break
        case "last_year":
          start = DateTime.now().minus({ year: 1 }).startOf("year")
          end = DateTime.now().minus({ year: 1 }).endOf("year")
          break
        case "all":
        default:
          start = null
          end = null
      }
      setFilters(filters => ({ ...filters, dateRange: { start, end } }))
    },
    [setFilters]
  )

  const stats = React.useMemo(() => {
    return {
      statusOffers: compileStatusStatisticsOffer(filtered),
      statusProjects: compileStatusStatisticsProject(filteredProjects),
    }
  }, [filtered, filteredProjects])

  React.useEffect(() => {
    if (!Array.isArray(events)) {
      return { tasks: [], date: Date.now() }
    }

    let filtered = [...events]

    if (eventFilters.type.length > 0) {
      filtered = filtered.filter(event => {
        if (eventFilters.type.includes("projects")) {
          return (
            eventFilters.type.includes(event.type) ||
            eventFilters.type.includes(event.subtype)
          )
        }

        if (event.type === "leaves" && eventFilters.type.includes("leaves")) {
          return event.type === "leaves" && event.subtype !== "holiday"
        }

        if (event.type === "leaves" && eventFilters.type.includes("holiday")) {
          return event.subtype === "holiday"
        }

        return eventFilters.type.includes(event.type)
      })
    }

    if (eventFilters?.dateRange?.end && eventFilters?.dateRange?.start) {
      const { start, end } = eventFilters.dateRange

      filtered = filtered.filter(event => {
        let eventStart = DateTime.fromJSDate(event.starts_at)
        let eventEnd = DateTime.fromJSDate(event.ends_at)

        if (event.subtype === "projects") {
          const project = events.find(
            e => e.id === event.taskable_id && e.type === "projects"
          )

          if (project) {
            eventStart = DateTime.fromJSDate(project.starts_at)
            eventEnd = DateTime.fromJSDate(project.ends_at)
          }
        }

        const startIsInRange = start <= eventStart && eventStart <= end
        const endIsInRange = start <= eventEnd && eventEnd <= end

        return startIsInRange || endIsInRange
      })
    }

    let projectDisplayOrderIndex = []

    filtered = filtered
      .map(event => {
        const ganttEvent = {
          event,
          start: event.starts_at,
          end: event.ends_at,
          name: event.titleGantt ?? event.title,
          id: event.id + "_" + event.type,
          type: event.type === "projects" ? "project" : "task",
          laravelType: event.type,
          progress: 0,
          isDisabled: event.isDisabled ?? false,
          styles: {
            backgroundColor: colors[event.type],
            backgroundSelectedColor: "#888",
          },
        }

        if (event.taskable_type && event.taskable_type === "project") {
          ganttEvent.project = event.taskable_id + "_projects"
        }

        if (event.type === "projects") {
          ganttEvent.hideChildren = filtered.some(
            e => e.taskable_id === event.id && e.taskable_type === "project"
          )
            ? true
            : undefined
        }

        return ganttEvent
      })
      .map((event, i) => {
        event.displayOrder = (i + 1) * 100
        if (event.type === "project") {
          projectDisplayOrderIndex[event.id] = event.displayOrder
        }
        return event
      })
      .map((event, i) => {
        if (projectDisplayOrderIndex[event.project]) {
          event.displayOrder =
            parseInt(projectDisplayOrderIndex[event.project]) + i + 1
        }
        return event
      })

    const date = ["this_year", "now"].includes(eventFilters.year)
      ? Date.now()
      : filtered[0]?.start
      ? DateTime.fromJSDate(filtered[0].start).toMillis()
      : null

    setTasks({ tasks: filtered, date })
  }, [events, eventFilters])

  const handleExpanderClick = React.useCallback(task => {
    setTasks(tasks => ({
      ...tasks,
      tasks: tasks.tasks.map(t => (t.id === task.id ? task : t)),
    }))
  }, [])

  return (
    <>
      {!user.hasRole("manager") ? (
        <div className="grid columns_2">
          <TodayPanel />
          <NextTasksPanel />
          <TimesheetsPanel />
          <LeavesPanel />
        </div>
      ) : (
        <Tabs alignControls="start" margin={{ top: "60px" }}>
          {user.can("leave_list") && (
            <Tab title="Planification">
              <Box
                pad={isFullscreen ? "medium" : { vertical: "medium" }}
                ref={fullscreenRef}
                style={{
                  backgroundColor: "#fff",
                  overflow: isFullscreen ? "auto" : null,
                }}
              >
                <Box
                  direction="row"
                  align="start"
                  wrap
                  gap="small"
                  height={isFullscreen ? { min: "94px" } : null}
                >
                  <FormField label="Période">
                    <Select
                      dropProps={{ inline: true }}
                      onChange={handleEventsSelectChange}
                      labelKey="label"
                      defaultValue="now"
                      valueKey={{
                        key: "value",
                        reduce: true,
                      }}
                      options={[
                        {
                          value: "now",
                          label: "Maintenant + 365j",
                        },
                        {
                          value: "last_year",
                          label: "L'année passée",
                        },
                        {
                          value: "this_year",
                          label: "Cette année",
                        },
                        {
                          value: "next_year",
                          label: "L'année prochaine",
                        },
                      ]}
                    />
                  </FormField>
                  <FormField label="Type">
                    <SelectMultiple
                      dropProps={{ inline: true }}
                      onChange={({ value }) => {
                        setEventFilters(filters => ({
                          ...filters,
                          type: value,
                        }))
                      }}
                      multiple
                      labelKey="label"
                      placeholder="Choisir un type"
                      valueKey={{
                        key: "value",
                        reduce: true,
                      }}
                      options={[
                        {
                          value: "tasks",
                          label: "Tâches",
                        },
                        {
                          value: "projects",
                          label: "Projets",
                        },
                        {
                          value: "leaves",
                          label: "Absences",
                        },
                        {
                          value: "holiday",
                          label: "Vacances",
                        },
                      ]}
                    />
                  </FormField>
                  <FormField label="Vue">
                    <Select
                      dropProps={{ inline: true }}
                      onChange={({ value }) => {
                        setEventFilters(filters => ({
                          ...filters,
                          viewMode: value,
                        }))
                      }}
                      labelKey="label"
                      defaultValue="Day"
                      valueKey={{
                        key: "value",
                        reduce: true,
                      }}
                      options={[
                        {
                          value: "Hour",
                          label: "Heure",
                        },
                        {
                          value: "Day",
                          label: "Jour",
                        },
                        {
                          value: "Week",
                          label: "Semaine",
                        },
                        {
                          value: "Month",
                          label: "Mois",
                        },
                      ]}
                    />
                  </FormField>
                  <Box margin={{ bottom: "small", left: "auto" }}>
                    <Button
                      onClick={() => {
                        toggle()
                      }}
                      label={isFullscreen ? "sortir" : "plein écran"}
                    />
                  </Box>
                </Box>
                <MyGantt
                  viewDate={tasks.date}
                  mutations={rescheduleMutations}
                  events={tasks.tasks}
                  handleExpanderClick={handleExpanderClick}
                  isFullscreen={isFullscreen}
                />
              </Box>
            </Tab>
          )}
          <Tab title="Calendrier">
            <Box pad={{ vertical: "medium" }}>
              <Box align="end" margin={{ bottom: "small" }}>
                <Button
                  onClick={() => {
                    dispatch({
                      type: "SHOW",
                      component: "SingleTask",
                    })
                  }}
                  label="Ajouter une tâche"
                />
              </Box>
              <MyCalendar mutations={rescheduleMutations} events={events} />
            </Box>
          </Tab>
          {user.is_admin && (
            <Tab
              title={
                <Box direction="row" gap="small" align="center">
                  Statistiques
                  <AdminVisibility />
                </Box>
              }
            >
              <Box pad={{ vertical: "medium" }}>
                <Box direction="row" align="start" wrap gap="small">
                  <FormField label="Période">
                    <Select
                      labelKey="label"
                      valueKey="value"
                      defaultValue="all"
                      options={[
                        { value: "all", label: "Tout voir" },
                        { value: "last 7 days", label: "7 derniers jours" },
                        { value: "last 28 days", label: "28 derniers jours" },
                        { value: "last 3 months", label: "3 derniers mois" },
                        { value: "this_year", label: "Cette année" },
                        { value: "last_year", label: "L'année passée" },
                      ]}
                      onChange={handleSelectChange}
                    />
                  </FormField>
                </Box>
                <Grid columns={{ count: "fill", size: "360px" }} gap="small">
                  <div>
                    <Heading level={2}>Offres</Heading>
                    <Stats
                      title="Status"
                      values={stats.statusOffers}
                      boxProps={{ margin: { bottom: "small" } }}
                      onLabelClick={({ key }) => {
                        navigate(`/simple-offers`, {
                          state: { preset: { status: key } },
                        })
                      }}
                    />
                  </div>
                  <div>
                    <Heading level={2}>Projets</Heading>
                    <Stats
                      title="Status"
                      values={stats.statusProjects}
                      boxProps={{ margin: { bottom: "small" } }}
                      onLabelClick={({ key }) => {
                        navigate(`/projects`, {
                          state: { preset: { status: key } },
                        })
                      }}
                    />
                  </div>
                </Grid>
              </Box>
            </Tab>
          )}
        </Tabs>
      )}
    </>
  )
}

export { Dashboard }

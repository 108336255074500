import { RadioInput, TextInput, Tags, ResourceInput, Jsx } from "./utils/Input"
import i18n from "i18next"
import { TagsColumn, TextColumn } from "./utils/Columns"
import { PermissionLink } from "../components/PermissionLink.jsx"
import {
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  selectAllCustomers,
  useGetCustomersQuery,
} from "../redux/customersSlice"
import { store } from "../store"
import { selectRegieRegieContacts } from "../redux/Contacts/regieContactSlice.js"
import { selectAllRegies } from "../redux/Contacts/regieSlice.js"
import { RegieResource } from "./Contacts/RegieResource.js"
import { RegieContactResource } from "./Contacts/RegieContactResource.js"
import { KdriveUrl } from "../components/KdriveValuePair.jsx"
import { Anchor, Box } from "grommet"
import { CUSTOMER_TAGS } from "../utils/constants.js"

export class CustomerRessource {
  static name = "customer"

  static permissionPrefix = "customer"

  static indexTitle = "Clients"

  static oneTitle = "un client"

  static fuseConfig = [
    {
      name: "name",
      weight: 3,
    },
    {
      name: "company",
      weight: 3,
    },
    {
      name: "zipcity",
      weight: 4,
    },
    {
      name: "humanId",
      weight: 3,
    },
  ]

  static methods = {
    load: useGetCustomersQuery,
    add: useAddCustomerMutation,
    update: useUpdateCustomerMutation,
    delete: useDeleteCustomerMutation,
  }

  static loadDependencies() {
    return [RegieResource.methods.load(), RegieContactResource.methods.load()]
  }

  static all() {
    return selectAllCustomers(store.getState())
  }

  static form(data = {}, user = {}) {
    const regies = selectAllRegies(store.getState()).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    const contacts = selectRegieRegieContacts(
      store.getState(),
      data.regie_id
    ).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    return {
      defaultValue: {
        type: "private",
      },
      fields: [
        new RadioInput("type")
          .label("Type")
          .options([
            { label: "Privé", value: "private" },
            { label: "Entreprise", value: "company" },
            { label: "PPE", value: "ppe" },
          ])
          .addInputProps({ direction: "row" })
          .defaultValue("private")
          .display(() => i18n.t(`customer.type.${data.type}`))
          .get(),

        new Jsx("kdrive")
          .label("Fichiers")
          .display(() => <KdriveUrl url={data.getDriveFolderUrl()} />)
          .get(),

        new Jsx("humanId")
          .label("Numéro de prospect")
          .display(() => data.humanId)
          .get(),

        new Jsx("address")
          .label("Coordonnées")
          .display(() => (
            <Box
              style={{ color: "#777776", fontSize: "18px", lineHeight: "24px" }}
            >
              {data.getAddressLines().map((line, i) => (
                <div key={i}>{line}</div>
              ))}
              {data.email && (
                <Anchor href={`mailto:${data.email}`}>{data.email}</Anchor>
              )}
              {data.phone && (
                <Anchor href={`tel:${data.phone.replaceAll(" ", "")}`}>
                  {data.phone}
                </Anchor>
              )}
              {data.mobile && (
                <Anchor href={`tel:${data.mobile.replaceAll(" ", "")}`}>
                  {data.mobile}
                </Anchor>
              )}
            </Box>
          ))
          .get(),

        new Tags("tags")
          .options(CUSTOMER_TAGS)
          .label("Étiquettes")
          .defaultValue(["HabitatJardin2025"])
          .get(),

        new RadioInput("title")
          .label("Titre")
          .hiddenOnDetail()
          .addInputProps({ direction: "row" })
          .options([
            { label: "Mme", value: "mrs" },
            { label: "Mr", value: "mr" },
          ])
          .get(),

        new TextInput("company")
          .label("Entreprise")
          .hidden(user.hasRole("foire"))
          .hiddenOnDetail()
          .required(data.type === "company")
          .get(),

        new TextInput("name")
          .label("Nom & prénom")
          .hiddenOnDetail()
          .required()
          .get(),

        new TextInput("street")
          .hiddenOnDetail()
          .label("Rue")
          .hidden(user.hasRole("foire"))
          .get(),

        new TextInput("street_number")
          .hiddenOnDetail()
          .label("Numéro")
          .hidden(user.hasRole("foire"))
          .get(),

        new TextInput("zip_code").hiddenOnDetail().label("NPA").get(),

        new TextInput("city").hiddenOnDetail().label("Localité").get(),

        new TextInput("parcel_number")
          .label("Numéro de parcelle")
          .hidden(user.hasRole("foire"))
          .get(),

        new TextInput("phone")
          .hiddenOnDetail()
          .label("Téléphone")
          .hidden(user.hasRole("foire"))
          .get(),

        new TextInput("mobile").hiddenOnDetail().label("Tél. mobile").get(),

        new TextInput("email")
          .hiddenOnDetail()
          .label("Email")
          .hidden(user.hasRole("foire"))
          .get(),

        new ResourceInput("regie_id")
          .label("Régie")
          .options(regies)
          .resourcePath("regies")
          .permission("regie_view")
          .searchable()
          .hiddenOnDetail(!data?.regie_id)
          .get(),

        new ResourceInput("regie_contact_id")
          .label("Contact dans la régie")
          .options(contacts)
          .resourcePath("regie_contacts")
          .permission("regie_contact_view")
          .searchable()
          .hidden(!data?.regie_id)
          .hiddenOnDetail(!data?.regie_contact_id)
          .get(),
      ],
    }
  }

  static table() {
    return {
      filters: [
        new Tags("tags").options(CUSTOMER_TAGS).label("Étiquettes").get(),
      ],
      columns: [
        new TextColumn("name")
          .label("Nom")
          .render(item => (
            <PermissionLink
              to={`/customers/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              {item.getTitle()}
              <br />
              <small style={{ color: "#777" }}>{item.humanId}</small>
            </PermissionLink>
          ))
          .get(),

        new TextColumn("zipcity").label("Localité").get(),

        new TextColumn("phone").label("Téléphone").get(),

        new TextColumn("mobile").label("Mobile").get(),

        new TextColumn("email").label("Email").get(),

        new TagsColumn("tags").label("Étiquettes").get(),
      ],
    }
  }
}
